<template>
    <div class="wrapper-content-page">
        <div class="block-info">
            <div class="block-info__logo">
                <img
                    :src="
                        require('@/assets/images/main/home-page/Capy_sample.png')
                    "
                    data-aos="fade-down"
                    @click="$router.push('/')"
                />
            </div>

            <h3 class="block-info__title" data-aos="fade-right">
                Capy Privacy Policy
            </h3>

            <div class="block-info__content">
                <p class="desc-text desc-text--note" data-aos="fade">
                    *Last updated: 27 August 2021*
                </p>

                <p class="desc-text" data-aos="fade">
                    Please read through this privacy policy (“<b
                        >Privacy Policy</b
                    >”) carefully as it explains to you how 55 Minutes Pte. Ltd.
                    (UEN: 201529015M), a company duly incorporated in Singapore
                    (“<b>we”</b>, “<b>us”</b> or “<b>our”</b>), collects, uses,
                    discloses, retains and otherwise processes Personal Data in
                    accordance with the Personal Data Protection Act
                    (“<b>PDPA”</b>).
                </p>

                <p class="desc-text" data-aos="fade">
                    This Privacy Policy applies in conjunction with, and should
                    be read together with, the
                    <router-link to="/terms">Terms of Service</router-link>.
                    Unless otherwise defined, capitalised terms used in this
                    Privacy Policy have the same meanings given to them in the
                    Terms of Service.
                </p>

                <h3 class="title-text" data-aos="fade-right">1. GENERAL</h3>

                <p class="desc-text" data-aos="fade">
                    “<b>Personal Data</b>” means data, whether true or not,
                    about a person who can be identified: (a) from that data; or
                    (b) from that data and other information to which we have or
                    are likely to have access. However, Personal Data does not
                    include information that has been made anonymous or
                    aggregated so that it can no longer be used to identify a
                    specific person, whether in combination with other
                    information or otherwise.
                </p>

                <p class="desc-text" data-aos="fade">
                    By accessing, visiting, browsing and/or using the Service,
                    you consent to us collecting, using, disclosing, storing and
                    processing your Personal Data as described in this Privacy
                    Policy. If you do not agree with any of its terms, you
                    should immediately discontinue use of the Website and/or
                    Service.
                </p>

                <p class="desc-text" data-aos="fade">
                    This Privacy Policy supplements but does not supersede nor
                    replace any other consent which you may have previously
                    provided to us, nor does it affect any rights which we may
                    have at law in connection with the collection, use or
                    disclosure of your Personal Data.
                </p>

                <p class="desc-text" data-aos="fade">
                    We may revise this Privacy Policy at any time in our sole
                    discretion by posting the revised version(s) on our Website.
                    You agree to be bound by all such changes to the Privacy
                    Policy.
                </p>

                <p class="desc-text" data-aos="fade">
                    If you disagree with this Privacy Policy, or any changes to
                    it, you must stop using the Service. It is your
                    responsibility to check this Privacy Policy periodically
                    when you use the Service. Your continued use of the Service
                    after changes to the Privacy Policy have been made will
                    constitute your acceptance of the revised Privacy Policy.
                </p>

                <h3 class="title-text" data-aos="fade-right">
                    2. CONTACT INFORMATION
                </h3>

                <p class="desc-text" data-aos="fade">
                    If you have any queries or requests relating to this Privacy
                    Policy or your Personal Data, please contact our Data
                    Protection Officer using the following contact details:
                </p>

                <div class="desc-text" data-aos="fade">
                    <p>Capy ℅ 55 Minutes Pte Ltd</p>
                    <p>284 River Valley Road</p>
                    <p>01-01 Singapore 238325</p>
                    <p>
                        Email:
                        <a href="mailto: hello@55mins.com">hello@55mins.com</a>
                    </p>
                </div>

                <h3 class="title-text" data-aos="fade-right">
                    3. PERSONAL DATA WE COLLECT
                </h3>

                <div class="desc-text" data-aos="fade">
                    <span>
                        We generally do not collect your Personal Data unless:
                    </span>

                    <p class="is-flex">
                        <span class="mr-4">(a)</span>
                        <span
                            >it is provided to us voluntarily by you directly or
                            via a third party who has been duly authorised by
                            you to disclose your Personal Data to us (your
                            “authorised representative”), you (or your
                            authorised representative) have been notified of the
                            purposes for which it is collected and you (or your
                            authorised representative) have provided your
                            consent to its collection and usage for such
                            purposes (by virtue of this Privacy Policy or a
                            separate consent process);
                        </span>
                    </p>

                    <p class="is-flex">
                        <span class="mr-4">(b)</span>
                        <span
                            >it is provided to us voluntarily by you for a
                            purpose, and it is reasonable in the circumstances
                            that you are deemed to have consented to our
                            collection, use and disclosure of that Personal Data
                            for that purpose; or
                        </span>
                    </p>

                    <p class="is-flex">
                        <span class="mr-4">(c)</span>
                        <span
                            >collection and use of Personal Data without consent
                            is permitted or required by applicable laws.
                        </span>
                    </p>
                </div>

                <div class="desc-text" data-aos="fade">
                    <span>
                        We may collect your Personal Data whenever you:
                    </span>

                    <p class="is-flex">
                        <span class="mr-4">(a)</span>
                        <span>access and/or use the Website and/or App; </span>
                    </p>

                    <p class="is-flex">
                        <span class="mr-4">(b)</span>
                        <span>register for an Account with us; </span>
                    </p>

                    <p class="is-flex">
                        <span class="mr-4">(c)</span>
                        <span
                            >submit a form or enquiry through the Website and/or
                            App;
                        </span>
                    </p>

                    <p class="is-flex">
                        <span class="mr-4">(d)</span>
                        <span>update or add information to your Account; </span>
                    </p>

                    <p class="is-flex">
                        <span class="mr-4">(e)</span>
                        <span
                            >correspond with us, including by interacting with
                            us or requesting that we contact you;
                        </span>
                    </p>

                    <p class="is-flex">
                        <span class="mr-4">(f)</span>
                        <span
                            >consent to be included in our email and/or other
                            mailing list; and/or
                        </span>
                    </p>

                    <p class="is-flex">
                        <span class="mr-4">(g)</span>
                        <span
                            >provide us information, including in response to a
                            request by us, by submitting forms relating to the
                            Service and for any other reason.
                        </span>
                    </p>
                </div>

                <p class="desc-text" data-aos="fade">
                    If you register for a Parent Account with us, we may collect
                    your first and last name, email address, and password, and
                    phone number.
                </p>

                <p class="desc-text" data-aos="fade">
                    If you/your child registers for a Child Account with us, we
                    may collect your/your child’s nickname, PIN, grade and date
                    of birth].
                </p>

                <p class="desc-text" data-aos="fade">
                    If you use the Digital Time Capsule feature in our App, we
                    may also collect and store your voice recordings.
                </p>

                <p class="desc-text" data-aos="fade">
                    <b>Minors</b>. We protect the Personal Data of your
                    child/ward under your Child Account(s), by collecting only
                    minimal mandatory information necessary for such child/ward
                    to register for a Child Account and for you as the parent
                    user of the Parent Account, to identify them easily via the
                    App. If you are the parent or legal guardian of a child who
                    is using the Service and your child’s Account was not set up
                    by you or if you did not give consent to the same, you can
                    submit a request via email to our Data Protection Officer to
                    have that child’s account removed.
                </p>

                <h3 class="title-text" data-aos="fade-right">
                    4. USE AND DISCLOSURE OF PERSONAL DATA
                </h3>

                <div class="desc-text" data-aos="fade">
                    <span>
                        <b>Use of Personal Data</b>. We may collect and use your
                        Personal Data for any or all of the following purposes:
                    </span>

                    <p class="is-flex">
                        <span class="mr-4">(a)</span>
                        <span
                            >performing obligations in the course of or in
                            connection with our provision of the Service (and
                            other goods or services requested by you);
                        </span>
                    </p>

                    <p class="is-flex">
                        <span class="mr-4">(b)</span>
                        <span
                            >providing you with personalised services and/or to
                            customise your user experience of the App;
                        </span>
                    </p>

                    <p class="is-flex">
                        <span class="mr-4">(c)</span>
                        <span>verifying your identity; </span>
                    </p>

                    <p class="is-flex">
                        <span class="mr-4">(d)</span>
                        <span
                            >responding to, handling, and processing queries,
                            requests, applications, complaints, feedback and
                            Submissions from you;
                        </span>
                    </p>

                    <p class="is-flex">
                        <span class="mr-4">(e)</span>
                        <span
                            >generally administering and/or managing your
                            relationship with us;
                        </span>
                    </p>

                    <p class="is-flex">
                        <span class="mr-4">(f)</span>
                        <span
                            >sending you information on any updates or changes
                            to the Service (including notifying you of any
                            planned or unscheduled downtime);
                        </span>
                    </p>

                    <p class="is-flex">
                        <span class="mr-4">(g)</span>
                        <span
                            >sending you information on any updates or changes
                            to our Terms, Policies and/or other legal or
                        </span>
                    </p>

                    <p class="is-flex">
                        <span class="mr-4">(h)</span>
                        <span
                            >sending you our newsletters, or advertising,
                            marketing and promotional communications, where you
                            have requested or consented to be on our mailing
                            list or to receive such communications;
                        </span>
                    </p>

                    <p class="is-flex">
                        <span class="mr-4">(i)</span>
                        <span
                            >any purpose for which you have provided your
                            Personal Data;
                        </span>
                    </p>

                    <p class="is-flex">
                        <span class="mr-4">(j)</span>
                        <span
                            >any other incidental business and/or administrative
                            purposes related to or in connection with the above;
                            and/or
                        </span>
                    </p>

                    <p class="is-flex">
                        <span class="mr-4">(k)</span>
                        <span
                            >such as we reasonably believe to be necessary to:
                        </span>
                    </p>

                    <p class="is-flex ml-5">
                        <span class="mr-4">(i)</span>
                        <span
                            >comply with applicable laws, regulations, codes of
                            practice, guidelines and/or rules or to assist in
                            law enforcement and investigations conducted by any
                            governmental and/or regulatory authority;
                        </span>
                    </p>

                    <p class="is-flex ml-5">
                        <span class="mr-4">(ii)</span>
                        <span
                            >protect the rights, privacy, safety and/or property
                            of any person;
                        </span>
                    </p>

                    <p class="is-flex ml-5">
                        <span class="mr-4">(iii)</span>
                        <span
                            >protect the safety or integrity of the Service
                            (including to help prevent spam, abuse, or malicious
                            actors on the Service);
                        </span>
                    </p>

                    <p class="is-flex ml-5">
                        <span class="mr-4">(iv)</span>
                        <span
                            >prevent, detect, mitigate and investigate
                            potentially illegal acts, fraud and/or security
                            breaches and to assess and manage risk, including to
                            alert you if fraudulent activities have been
                            detected on your Account(s);
                        </span>
                    </p>

                    <p class="is-flex ml-5">
                        <span class="mr-4">(v)</span>
                        <span>enforce the Terms; and/or</span>
                    </p>

                    <p class="is-flex ml-5">
                        <span class="mr-4">(vi)</span>
                        <span
                            >allow us to pursue available remedies or limit the
                            damages we may sustain.
                        </span>
                    </p>
                </div>

                <div class="desc-text" data-aos="fade">
                    <span>
                        <b>Disclosure of Personal Data</b>. We may disclose your
                        Personal Data:
                    </span>

                    <p class="is-flex">
                        <span class="mr-4">(a)</span>
                        <span
                            >where such disclosure is required for performing
                            obligations in the course of or in connection with
                            our provision of the Service (or other goods and
                            services requested by you); or
                        </span>
                    </p>

                    <p class="is-flex">
                        <span class="mr-4">(b)</span>
                        <span
                            >to any unaffiliated third parties including our
                            third party service providers, agents and other
                            organisations we have engaged, and relevant
                            governmental and/or regulatory authorities, whether
                            in Singapore or abroad, for the aforementioned
                            purposes.
                        </span>
                    </p>
                </div>

                <p class="desc-text" data-aos="fade">
                    <b>If you provide Personal Data of third parties to us</b>.
                    If you provide us with the Personal Data of a spouse, child,
                    parent, authorised representative or other third party
                    individual (e.g. by sending emails or email invitations to
                    others through the App), you represent and warrant that you
                    have obtained the consent of such third party individual to
                    the collection, use and disclosure of their Personal Data
                    under this Privacy Policy.
                </p>

                <h3 class="title-text" data-aos="fade-right">
                    5. WITHDRAWING YOUR CONSENT
                </h3>

                <p class="desc-text" data-aos="fade">
                    The consent that you provide for the collection, use,
                    disclosure, retention and processing of your Personal Data
                    will remain valid until such time as it is withdrawn by you
                    in writing. You may withdraw your consent and request for us
                    to stop collecting, using, disclosing, retaining and/or
                    processing your Personal Data for any or all of the purposes
                    listed above by submitting your request via email to our
                    Data Protection Officer.
                </p>

                <p class="desc-text" data-aos="fade">
                    <b>Response time</b>. Upon receipt of your written request
                    to withdraw your consent, we may require reasonable time
                    (depending on the complexity of your request and its impact
                    on our relationship with you) for your request to be
                    processed and for us to notify you of the consequences of us
                    acceding to the same, including any legal consequences which
                    may affect your rights and liabilities to us.
                </p>

                <p class="desc-text" data-aos="fade">
                    In general, we shall seek to process your request within
                    fourteen (14) days of receiving it. You acknowledge that we
                    will not be able to remove your Personal Data from the
                    database of any other users with whom you have shared such
                    information or from the databases of third parties which you
                    previously authorised us to share such information with.
                </p>

                <p class="desc-text" data-aos="fade">
                    <b>Cessation of service</b>. Whilst we respect your decision
                    to withdraw your consent, please note that depending on the
                    nature and scope of your request, we may not be in a
                    position to continue providing the Service to you and may
                    also need to terminate your Account(s) with us. We shall, in
                    such circumstances, notify you before completing the
                    processing of your request. Should you decide to cancel your
                    withdrawal of consent, please inform us in writing via email
                    to our Data Protection Officer within 14 days of your
                    request for withdrawal of consent, failing which we shall
                    proceed as you initially instructed.
                </p>

                <p class="desc-text" data-aos="fade">
                    Please note that withdrawing consent does not affect our
                    right to continue to collect, use, disclose, retain and/or
                    process Personal Data where such collection, use,
                    disclosure, retention and/or processing is permitted or
                    required under applicable laws.
                </p>

                <p class="desc-text" data-aos="fade">
                    <b>Unsubscribe</b>. If you do not wish to receive e-mail,
                    messages or other communications from us, you can
                    unsubscribe from such communications by using the
                    unsubscribe facility that may be provided with such e-mail,
                    message or communication, or submit a written request via
                    email to our Data Protection Officer. Please note that if
                    you do opt out of receiving marketing-related communications
                    from us, we may still send you important administrative
                    messages, and that you cannot opt out of receiving such
                    administrative or other important messages.
                </p>

                <h3 class="title-text" data-aos="fade-right">
                    6. ACCESSING AND CORRECTING YOUR PERSONAL DATA
                </h3>

                <p class="desc-text" data-aos="fade">
                    <b>Accuracy of Personal Data</b>. We generally rely on
                    Personal Data provided by you, or your authorised
                    representative.
                </p>

                <p class="desc-text" data-aos="fade">
                    <b>Updating/correcting Personal Data</b>. In order to ensure
                    that your Personal Data is current, complete and accurate,
                    please update us if there are changes to your Personal Data
                    by updating your Personal Data in your Account(s) (if
                    applicable) or submitting your request via email to our Data
                    Protection Officer.
                </p>

                <p class="desc-text" data-aos="fade">
                    <b>Access request</b>. To request access to your Personal
                    Data, please submit your request via email to our Data
                    Protection Officer. Please note that a reasonable fee may be
                    charged for an access request. If so, we will inform you of
                    the fee before processing your request.
                </p>

                <p class="desc-text" data-aos="fade">
                    <b>Response time</b>. We will respond to your request for
                    access to or correction of your Personal Data as soon as
                    reasonably possible. In general, our response will be within
                    fourteen (14) days. Should we not be able to respond to your
                    request within thirty (30) days after receiving your
                    request, we will inform you in writing within thirty (30)
                    days of the time by which we will be able to respond to your
                    request.
                </p>

                <h3 class="title-text" data-aos="fade-right">
                    7. PROTECTION OF PERSONAL DATA
                </h3>

                <p class="desc-text" data-aos="fade">
                    To safeguard your Personal Data from unauthorised access,
                    collection, use, disclosure, copying, modification, disposal
                    or similar risks, we have introduced commercially reasonable
                    physical, administrative, technical and electronic measures
                    and safeguards such as minimised collection of Personal
                    Data, authentication and access controls (such
                    as\need-to-basis for data disclosure, etc), encryption of
                    data, regular patching of operating system and other
                    software, securely erase storage media in devices before
                    disposal.
                </p>

                <p class="desc-text" data-aos="fade">
                    While we strive to protect the security of your Personal
                    Data and are constantly reviewing and enhancing our
                    information security measures, you should be aware that no
                    security measures that we take to protect your information
                    is impenetrable or absolutely guaranteed to avoid
                    unauthorised access or use of your Personal Data. Further,
                    no method of transmission over the Internet or method of
                    electronic storage is completely secure.
                </p>

                <p class="desc-text" data-aos="fade">
                    You are solely responsible for safeguarding the usernames
                    and/or passwords of your Account(s) and keeping them
                    confidential. You acknowledge that if you lose control of
                    your usernames and/or passwords, you may lose substantial
                    control over your Personal Data, and you shall be solely and
                    fully liable for any disclosure or unauthorised use thereof.
                </p>

                <h3 class="title-text" data-aos="fade-right">
                    8. RETENTION OF PERSONAL DATA
                </h3>

                <p class="desc-text" data-aos="fade">
                    We will retain your Personal Data (including Personal Data
                    from terminated Accounts) for as long as is necessary to
                    fulfil the purpose for which it is collected, or as required
                    or permitted by applicable laws or under this Privacy
                    Policy.
                </p>

                <p class="desc-text" data-aos="fade">
                    Please note that the purposes listed in this Policy for
                    which Personal Data may be collected, used and disclose, may
                    continue to apply even in situations where your relationship
                    with us has been terminated or altered in any way, for a
                    reasonable period thereafter (including, where applicable, a
                    period to enable us to enforce our rights under any contract
                    with you).
                </p>

                <p class="desc-text" data-aos="fade">
                    Typically, our legitimate business purposes do not require
                    us to retain your Personal Data for more than 180 days
                    following the termination of your Account. However, we may
                    retain your personal data for longer periods for legal
                    purposes, or if otherwise required or permitted by
                    applicable laws.
                </p>

                <div class="desc-text" data-aos="fade">
                    <span>
                        We will cease to retain your Personal data (or remove
                        the means by which such data can be associated with you)
                        where:
                    </span>

                    <p class="is-flex">
                        <span class="mr-4">(a)</span>
                        <span
                            >we no longer need the Personal Data for the
                            purposes for which it was collected, or legal or
                            business purposes and we are not otherwise entitled
                            to retain such data; and
                        </span>
                    </p>

                    <p class="is-flex">
                        <span class="mr-4">(b)</span>
                        <span
                            >we are not required by law, regulation or court
                            order to retain the information.
                        </span>
                    </p>
                </div>

                <p class="desc-text" data-aos="fade">
                    If Personal Data has been provided to third parties in
                    accordance with this Privacy Policy, retention of that
                    Personal Data will be subject to the policies of the
                    relevant third party.
                </p>

                <h3 class="title-text" data-aos="fade-right">
                    9. TRANSFERS OF PERSONAL DATA OUTSIDE OF SINGAPORE
                </h3>

                <p class="desc-text" data-aos="fade">
                    In order to provide you with the use of the Service and
                    related services and functions (including without limitation
                    customer support, app maintenance, back office functions,
                    etc.) we may need to allow our staff or third party service
                    providers (who may be located or whose servers and resources
                    may be located in a location other than your country of
                    residence) access to your Personal Data. Such transfers of
                    your Personal Data are necessary for us to provide the
                    Service as intended.
                </p>

                <p class="desc-text" data-aos="fade">
                    To the extent that we may need to transfer your Personal
                    Data outside of Singapore, we shall do so in accordance with
                    the PDPA to ensure that we provide a standard of protection
                    to personal data so transferred that is comparable to the
                    protection under the PDPA, including by ensuring that the
                    recipient is either in a jurisdiction which has comparable
                    data protection laws, or is contractually bound to protect
                    your Personal Data.
                </p>

                <p class="desc-text" data-aos="fade">
                    By using our Service, and providing us with your Personal
                    Data, you are explicitly consenting to the international
                    transfer and processing of such data in accordance with this
                    Privacy Policy, in full and informed knowledge of the risks
                    associated with such transfers and processing.
                </p>

                <p class="desc-text" data-aos="fade">
                    Third party service providers to whom your Personal Data may
                    be transferred overseas include the following:
                </p>

                <div class="desc-text" data-aos="fade">
                    <span>
                        <b>Microsoft (Analytics, Diagnostics)</b>: We use App
                        Center Diagnostics and App Center Diagnostics, services
                        provided by Microsoft, to
                    </span>

                    <p class="is-flex">
                        <span class="mr-4">&#9679;</span>
                        <span
                            >collect and analyze usage data in order to
                            understand usage patterns and improve our App.
                        </span>
                    </p>

                    <p class="is-flex">
                        <span class="mr-4">&#9679;</span>
                        <span>identify crashes and errors in the App. </span>
                    </p>

                    <span
                        >Your information (including unique device identifiers,
                        session durations, device models, operating systems,
                        geography, crash traces) may be stored and processed by
                        Microsoft in various locations outside Singapore, and
                        handled as described in their privacy policies at
                        https://docs.microsoft.com/en-us/appcenter/gdpr/data-from-your-end-users.
                    </span>
                </div>

                <p class="desc-text" data-aos="fade">
                    <b> Google (Analytics, Cloud Messaging)</b>: We use the
                    Google Analytics for Firebase and Firebase Crashlytics, to
                    collect and analyze usage data in order to understand usage
                    patterns and to improve our iOS app. We also use Firebase
                    Cloud Messaging to enable push notification to work and to
                    diagnose issues related to push notifications. These
                    services are provided by Google Asia Pacific Pte. Ltd.
                    (“Google”). Your information (including various usage data,
                    Firebase installation IDs, Analytics App Instance IDs,
                    anonymized IP addressed, session durations, device models,
                    operating systems, geography, crash traces, devices models)
                    may be stored or processed by Google in various locations
                    outside Singapore, and handled as described in their privacy
                    policies at
                    <a
                        href="https://firebase.google.com/support/privacy"
                        target="_blank"
                        >https://firebase.google.com/support/privacy</a
                    >.
                </p>

                <p class="desc-text" data-aos="fade">
                    Links to the privacy policies of third party services above
                    are provided for your convenience only, and may not be
                    current or updated. You agree to refer to the respective
                    websites of the relevant third party service provider for
                    up-to-date information on how they collect, use, disclose,
                    retain and process your Personal Data.
                </p>

                <h3 class="title-text" data-aos="fade-right">
                    10. NON-PERSONAL DATA
                </h3>

                <div class="desc-text" data-aos="fade">
                    <span>
                        When you access and/or use the Service, we may collect,
                        use, disclose, retain and/or process (or our systems may
                        automatically collect, use, disclose, retain and/or
                        process), directly or through third party applications,
                        data that cannot be used to identify any particular
                        individual and/or data that has been anonymised so that
                        it cannot be used to identify any particular individual,
                        including the following:
                    </span>

                    <p class="is-flex">
                        <span class="mr-4">(a)</span>
                        <span
                            >log data, including your unique device number, the
                            IP address of your computer or device, information
                            about your computer or mobile internet browser type
                            and operating system, the dates and times of your
                            access to and/or use of the Website and/or the App,
                            the number and frequency of visitors to and/or users
                            thereof, common entry and exit points into and from
                            the Website and/or the App, the number of page views
                            or page impressions that occur on the Website and/or
                            App, the number of unique visitors to and average
                            times spent by these visitors on the Website and/or
                            App;
                        </span>
                    </p>

                    <p class="is-flex">
                        <span class="mr-4">(b)</span>
                        <span
                            >session and usage data about your use of the
                            Website and/or the App, including connection and
                            service-related data such as information relating to
                            the connection request, server communication and
                            data sharing, network measurements, quality of
                            service and date, time and location of usage; and/or
                        </span>
                    </p>

                    <p class="is-flex">
                        <span class="mr-4">(c)</span>
                        <span
                            >aggregate information about access to and/or use of
                            the Website and/or the App (which may contain log
                            data and session and usage data) in respect of a
                            group or category of services or users but which
                            contains no personally identifiable information
                            about the users.
                        </span>
                    </p>

                    <span>(collectively “<b>Non-Personal Data</b>”). </span>
                </div>

                <p class="desc-text" data-aos="fade">
                    We may appoint third parties to aggregate or otherwise
                    anonymise data that is collected from you.
                </p>

                <div class="desc-text" data-aos="fade">
                    <span>
                        We may use Non-Personal Data for any purpose, including:
                    </span>

                    <p class="is-flex">
                        <span class="mr-4">(a)</span>
                        <span>for our own internal business purposes; </span>
                    </p>

                    <p class="is-flex">
                        <span class="mr-4">(b)</span>
                        <span
                            >to enable us and/or third parties appointed by us
                            to analyse, research and track access to and/or
                            usage of the Website and/or App, including to
                            conduct internal research on user demographics,
                            interests, behaviour and trends;
                        </span>
                    </p>

                    <p class="is-flex">
                        <span class="mr-4">(c)</span>
                        <span
                            >to provide, improve and modify the Website and/or
                            App; and
                        </span>
                    </p>

                    <p class="is-flex">
                        <span class="mr-4">(d)</span>
                        <span
                            >to disclose to third party media and/or research
                            organisations for the purpose of conducting industry
                            comparisons with other Internet portals.
                        </span>
                    </p>
                </div>

                <h3 class="title-text" data-aos="fade-right">10. COOKIES</h3>

                <p class="desc-text" data-aos="fade">
                    We, and third party applications that we use, may collect
                    data using “cookies” when you access and/or use the Website
                    and/or App. Cookies are small data files stored on your
                    computer’s or device’s hard drive. Each time you access
                    and/or use the Website and/or App from the same computer or
                    device, the cookie will be retrieved from your computer or
                    device, enabling the Website and/or App to recognise your
                    computer or device as having previously accessed and/or used
                    the Website and/or App. We use cookies to help us improve
                    the quality of access to and/or use of the Website and/or
                    App, and to provide more tailored and user-friendly services
                    to our visitors and/or users. For example, we use cookies to
                    store user preferences, track user trends and obtain
                    information about the use of the Website and/or App. This
                    information allows us to better administer the Website
                    and/or App and make the contents and advertisements you see
                    more relevant to you. Cookies also help you complete tasks
                    on the website and/or mobile application without having to
                    re-enter information when browsing from one page to another
                    or when visiting and/or using the Website and/or App later.
                </p>

                <p class="desc-text" data-aos="fade">
                    Our cookies cannot be used to get data from your hard drive,
                    to get your email address or any other data that can be
                    traced to you personally.
                </p>

                <p class="desc-text" data-aos="fade">
                    Most web browsers and devices can be set to notify you when
                    you receive a cookie or to prevent cookies from being sent.
                    However, if you use these features, you may limit the
                    functionality we can provide you when you access and/or use
                    the Website and/or App.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import marked from 'marked';

export default {
    methods: {
        marked,
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/page/Term.scss';
</style>
